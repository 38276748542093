import React, { useEffect, useState } from "react"
import { Helmet } from "react-helmet"
import { ContactForm, Layout } from "../../../components"
import ReferenceHeader from "../../../components/reference-detail/reference-header"
import ReferenceImageText from "../../../components/reference-detail/reference-image-text"
import OgImage from "../../../images/reference/wtb/og-image.png"
import Brand from "../../../images/reference/wtb/brand.jpg"
import Uvod from "../../../images/reference/wtb/uvod.jpg"
import Photo1 from "../../../images/reference/wtb/photo1.jpg"
import Photo2 from "../../../images/reference/wtb/photo2.jpg"
import Photo3 from "../../../images/reference/wtb/photo3.png"
import Photo4 from "../../../images/reference/wtb/photo4.jpg"
import Vilem from "../../../images/reference/wtb/vilem.jpg"
import Denisa from "../../../images/reference/wtb/denisa.jpeg"
import Logo from "../../../images/reference/wtb/logo.png"
import {
  ContainerCustom,
  Content,
  H2,
  Medailon,
  PaddingWrapper,
} from "@igloonet-web/shared-ui"
import { Col, Container, Row } from "reactstrap"
import TwoImagesNextToEachOther from "../../../components/reference-detail/two-images-to-next-each-other"

const WTB: React.FC = () => {
  const [isDesktop, setIsDesktop] = useState(false)

  useEffect(() => {
    const mediaQuery = window.matchMedia("(min-width: 900px)")

    const handleMediaQueryChange = (event) => {
      setIsDesktop(event.matches)
    }

    setIsDesktop(mediaQuery.matches)

    mediaQuery.addEventListener("change", handleMediaQueryChange)

    return () => {
      mediaQuery.removeEventListener("change", handleMediaQueryChange)
    }
  }, [])

  const ContentOfBlock = (
    <>
      <H2>Jak jsme postupovali</H2>
      <Content noPadding>
        <ol>
          <li>
            <strong>Desk research</strong>
            <p>
              Ještě před společným workshopem jsme si dali na jednu hromádku
              všechny interní dokumenty a&nbsp;plány, související se značkou
              a&nbsp;propagací WTB. Díky tomu jsme už před prvním setkáním lépe
              chápali některé souvislosti, a&nbsp;mohli se tak rovnou doptávat
              na podrobnosti.
            </p>
          </li>
          <li>
            <strong>Workshop ke strategii a&nbsp;značce</strong>
            <p>
              Abychom následné analýzy a&nbsp;výstupy stavěli na relevantních
              základech, setkali jsme se se zakladateli a&nbsp;marketérkami
              pekárny. Workshopovým způsobem jsme si upřesnili hlavní cíle
              a&nbsp;záměry jejich podnikání, esenci i&nbsp;symboly značky,
              cílové skupiny, konkurenci, ale také například dosavadní
              zkušenosti s&nbsp;reklamou či role v&nbsp;další spolupráci.
            </p>
          </li>

          <PaddingWrapper>
            <Medailon
              noMargin
              fullWidth
              noCover
              noPenguin
              personName="Vilém Ráček"
              position="zakladatel"
              img={Vilem}
            >
              <p>
                Velmi zajímavý pro nás byl úvodní workshop, na kterém jsme
                pomocí různých cvičení definovali naše silné stránky,
                charakteristické znaky i&nbsp;cílové skupiny. Myslím si, že pro
                každého podnikatele je užitečné nad svou značkou přemýšlet takto
                komplexně.
              </p>
            </Medailon>
          </PaddingWrapper>
          <li>
            <strong>Výstupy z workshopu</strong>
            <p>
              Na setkání jsme velké množství informací, souvislostí
              a&nbsp;závěrů rovnou zapisovali do mindmapy. Pro pravidelné
              používání je však příliš robustní, proto jsme to nejdůležitější
              shrnuli do přehledné Brand Canvas. Klient má tak k ruce
              strategický jednostránkový dokument, podle kterého se může
              v&nbsp;další práci orientovat.
            </p>
          </li>

          <Container className="mx-auto mb-5 text-center">
            <img
              src={Brand}
              alt=""
              className="img-fluid"
              loading="lazy"
              width={714}
              height={536}
            />
            <p className="text-center pt-3">
              <em>Ukázka sekce Brand komunikace z&nbsp;Brand Canvas</em>
            </p>
          </Container>

          <li>
            <strong>Audit sociálních sítí</strong>
            <p>
              V&nbsp;auditu se věnujeme současnému stavu komunikace –&nbsp;jak
              může na cílová publika působit, jaká témata se využívají
              a&nbsp;v&nbsp;jaké frekvenci či zdali je komunikace dostatečně
              výrazná a&nbsp;odlišitelná. Procházíme také samotný web, na který
              se v&nbsp;postech odkazuje, a&nbsp;správnost nastavení měření
              návštěvnosti. Komunikace WTB byla už v&nbsp;tento moment na velmi
              dobré úrovni. Mohli jsme tak plynule jen s&nbsp;několika
              doporučeními přejít k&nbsp;plánování obsahu.
            </p>
          </li>
          <li>
            <strong>Plánování obsahu</strong>
            <p>
              Obsahový plán neznamená, že musíte veškerý obsah striktně plánovat
              měsíce dopředu, navíc v&nbsp;prostředí, kde se každý den něco
              děje. I&nbsp;pro WTB jde o&nbsp;kostru hlavních témat, co se dají
              tvořit s&nbsp;předstihem či hromadně, s&nbsp;prostorem na to, co
              se zrovna namane. Vytvořili jsme přehlednou plánovací tabulku,
              která ve výsledku šetří čas a&nbsp;díky níž mohou ve WTB práci
              lépe plánovat.
            </p>
          </li>

          <Container className="text-center">
            <Row>
              <Col md={6}>
                <img
                  src={Photo3}
                  alt=""
                  width={532}
                  height={710}
                  className="mt-2 mr-2 img-fluid"
                  loading="lazy"
                />
              </Col>
              <Col md={6}>
                <img
                  src={Photo4}
                  alt=""
                  width={532}
                  height={710}
                  className="mt-2 img-fluid"
                  loading="lazy"
                />
              </Col>
            </Row>
            <p className="pt-2 mx-auto">
              <em>
                Ukázka příspěvků na sociálních sítích (Zdroj: FB stránka WTB)
              </em>
            </p>
          </Container>

          <li>
            <strong>Školení propagace na sítích</strong>
            <p>
              Jeden ze záměrů spolupráce směřomal k&nbsp;tomu, aby WTB zvládl
              vlastními silami ještě lépe šířit povědomí o&nbsp;svých pekárnách
              a&nbsp;produktech. Máme&#8209;li k dispozici kvalitní a pravidelný
              obsah, stačí jen pár kroků k jeho další podpoře. Vytvořili jsme
              proto jednoduchou strukturu kampaní, uzpůsobenou cílovým publikům,
              k&nbsp;pravidelné propagaci příspěvků mezi široká publika.
              Marketérky jsme v&nbsp;systému proškolili, aby vše zvládaly samy,
              případně s&nbsp;naší drobnou pomocí.
            </p>
          </li>

          <PaddingWrapper>
            <Medailon
              noMargin
              fullWidth
              noCover
              noPenguin
              personName="Denisa Marynčáková"
              position="vedoucí marketingu"
              img={Denisa}
            >
              <p>
                Díky spolupráci se pro nás nastavování reklamy na sociálních
                sítích stalo záležitostí na pár kliků. Dobře pracujeme nejen
                s&nbsp;reklamou, ale také s&nbsp;různými cílovými skupinami,
                které nám igloonet pomohl definovat. Nyní se jim snažíme nabízet
                zajímavý obsah na míru.
              </p>
            </Medailon>
          </PaddingWrapper>

          <li>
            <strong>Konzultace a&nbsp;podpora</strong>
            <p>
              Stavíte&#8209;li poprvé dům, hodí se vám k&nbsp;ruce dozor či
              zkušená pomoc. Začínáte&#8209;li s&nbsp;propagací, není to jiné.
              Po předání hlavní části práce jsme proto byli WTB dál
              k&nbsp;dispozici –&nbsp;buď jako poradní marketingový hlas či
              přímo pomoc při práci s&nbsp;reklamou. Pomáhali jsme např. také
              s&nbsp;plánem otevírání pekárny v&nbsp;Praze.
            </p>
          </li>
        </ol>
      </Content>
    </>
  )

  return (
    <Layout>
      <Helmet>
        <title>
          Spolupráce s W&T Bakery: propagace na sítích s řemeslnou péčí |
          igloonet.cz
        </title>
        <meta
          name="description"
          content={`„Díky spolupráci se pro nás nastavování reklamy na sociálních sítích stalo záležitostí na pár kliků. Dobře pracujeme také s různými cílovými skupinami, které nám igloonet pomohl definovat.“ Denisa Marynčáková, vedoucí marketingu`}
        />
        <meta
          property="og:title"
          content="Propagace na sítích s řemeslnou péčí | igloonet.cz"
        />
        <meta property="og:image" content={OgImage} />
      </Helmet>
      <ReferenceHeader
        companyName="William & Thomas Artisan Bakery"
        heading="Propagace na sítích s&nbsp;řemeslnou péčí pro W&T Bakery"
        date="květen 2022 – listopad 2022"
      />

      <ReferenceImageText
        right
        img={Uvod}
        eager
        alt=""
        logo={Logo}
        logoAlt="logo společnosti William & Thomas Artisan Bakery"
        link="//wtbakery.cz/"
      >
        <p>
          Řemeslná pekárna William &&nbsp;Thomas Artisan Bakery chce, aby
          kvalitní francouzské pečivo vonělo v&nbsp;ještě větším počtu
          domácností. Dali jsme proto hlavy dohromady, vytvořili základní
          strategii, obsahový plán a&nbsp;strukturu kampaní a&nbsp;klientovi
          ukázali, jak si propagaci sám spravovat a&nbsp;vyhodnocovat. Mrkněte
          se blíž a&nbsp;nechte si chutnat&nbsp;↓
        </p>
      </ReferenceImageText>

      <ReferenceImageText>
        <H2>William &&nbsp;Thomas Artisan Bakery</H2>
        <p>
          Zavítáte&#8209;li do Brna, omrkněte Petrov, prozkoumejte Jošta a&nbsp;
          užijte si skvělé slané i&nbsp;sladké francouzské pečivo u&nbsp;William
          &&nbsp;Thomas Artisan Bakery (dále také WTB). Zakladatelé řemeslné
          pekárny Vilém s&nbsp;Tomášem lpí na tradičních recepturách, které sami
          dál rozvíjí a&nbsp;pilují, a&nbsp;samozřejmě na kvalitních surovinách.
          A&nbsp;že jejich pečivo chutná, podtrhuje i&nbsp;fakt, že kromě tří
          brněnských pekáren si otevřeli další, tentokrát v&nbsp;Praze. Bon
          appétit!
        </p>
      </ReferenceImageText>

      <TwoImagesNextToEachOther
        img1={Photo1}
        img2={Photo2}
        width1={1654}
        width2={1654}
        height1={2068}
        height2={2068}
      />

      <PaddingWrapper>
        <Container className="m-auto">
          <H2>Cíle spolupráce</H2>
          <Content noPadding>
            <p className="pl-3">
              Mezi hlavní záměry tohoto typu spolupráce jsme zařadili:
            </p>
            <ol>
              <li>
                Nastavit strategii a&nbsp;plán dlouhodobé komunikace
                a&nbsp;propagace WTB na sociálních sítích.
              </li>
              <li>
                Předat strukturu placené propagace příspěvků na Facebooku
                a&nbsp; Instagramu tak, aby si klient zvládl reklamu sám
                spravovat.
              </li>
            </ol>
            <p>
              Na výstupu je klient tedy schopen sám či s&nbsp;malou pomocí
              výrazně šířit povědomí o&nbsp;značce a&nbsp;nabízených produktech.
              Staví přitom na pevných základech, umožňujících mu dělat správná
              a&nbsp; konzistentní rozhodnutí.
            </p>
          </Content>
        </Container>
      </PaddingWrapper>

      <PaddingWrapper>
        {isDesktop ? (
          <ContainerCustom>{ContentOfBlock}</ContainerCustom>
        ) : (
          <>{ContentOfBlock}</>
        )}
      </PaddingWrapper>

      <ContactForm contact="adam" />
    </Layout>
  )
}

export default WTB
