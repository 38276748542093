import React from "react"
import { Row, Col, Container } from "reactstrap"

type Props = {
  img1: string
  img2: string
  width1: number
  width2: number
  height1: number
  height2: number
  inRowsOnPhones?: boolean
}

const TwoImagesNextToEachOther: React.FC<Props> = ({
  img1,
  img2,
  width1,
  width2,
  height1,
  height2,
  inRowsOnPhones,
}) => {
  return (
    <Container className="text-center mb-3">
      <Row>
        <Col xs={inRowsOnPhones ? "12" : ""} md={inRowsOnPhones ? "6" : ""}>
          <img
            src={img1}
            alt=""
            width={width1}
            height={height1}
            className="mt-2 mr-2 img-fluid"
            loading="lazy"
          />
        </Col>
        <Col xs={inRowsOnPhones ? "12" : ""} md={inRowsOnPhones ? "6" : ""}>
          <img
            src={img2}
            alt=""
            width={width2}
            height={height2}
            className="mt-2 img-fluid"
            loading="lazy"
          />
        </Col>
      </Row>
    </Container>
  )
}

export default TwoImagesNextToEachOther
